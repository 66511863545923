<template>
    <styled-interface hide-toolbar>
        <styled-card
            class="flex ma-3 xs12 communication-center">
            <dismiss-slideout
                v-model="dismissSlideoutOpen"
                :alert-id="dismissId"
                @close-and-refresh="closeAndLoad" />
            <template #heading>
                Communication Center
            </template>

            <div class="groups-picker">
                <div class="groups-picker__holder">
                    <groups-picker-global />
                </div>
                <action-button
                    icon="refresh"
                    :disabled="loading"
                    @click="loadData">
                    Refresh
                </action-button>
            </div>

            <div v-if="loadingAll || agenciesLoading || groupsLoading">
                <loader />
            </div>
            <div
                v-show="!loadingAll && !agenciesLoading && !groupsLoading"
                class="cc">
                <div class="cc__alerts">
                    <div
                        ref="typesWrapper"
                        class="types">
                        <div
                            v-if="!loadingAll"
                            ref="typesAnchor" />
                        <div class="types__title">
                            Alerts
                        </div>
                        <div class="types__search">
                            <v-text-field
                                v-model="searchTypesField"
                                prepend-inner-icon="search"
                                type="string"
                                label="Search"
                                class="styled-field has-icon"
                                clearable />
                        </div>
                        <div class="types__content">
                            <div class="types__content-inner">
                                <template v-if="monitors.length">
                                    <div class="types__all">
                                        <div
                                            class="types__inner"
                                            :class="{'selected': alertType === '' && allSelected}"
                                            @click="selectAll">
                                            <icon
                                                name="alert"
                                                size="12" />
                                            <span class="types__text">All Alerts</span>
                                            <span class="types__count">{{ allAlertsCount }}</span>
                                        </div>
                                    </div>
                                    <div class="types__all">
                                        <div
                                            class="types__inner"
                                            :class="{'selected': alertType === '' && dismissedSelected}"
                                            @click="selectDismissed">
                                            <icon
                                                name="alert-muted"
                                                size="12" />
                                            <span class="types__text">Dismissed Alerts</span>
                                            <span class="types__count zero">{{ dismissedCount }}</span>
                                        </div>
                                    </div>
                                    <div
                                        v-for="item in filteredAlertTypes"
                                        :key="item.id"
                                        class="types__single">
                                        <div class="types__subtitle">
                                            <icon
                                                name="alert"
                                                size="12" />
                                            <span class="types__text">{{ item.name }}</span>
                                        </div>
                                        <div class="types__list">
                                            <div
                                                v-for="type in item.alert_types.data"
                                                :key="type.id"
                                                class="types__item">
                                                <div
                                                    class="types__inner"
                                                    :class="{'selected': type.id === alertType}"
                                                    @click="selectAlertType(type.id)">
                                                    <span class="types__text">{{ type.display_name }}</span>
                                                    <span
                                                        class="types__count"
                                                        :class="{'zero': type.count === 0}">{{ type.count }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cc__table">
                    <custom-table
                        ref="alertsTable"
                        name="alerts"
                        :fields="fields"
                        :items="alerts"
                        :loading="loading"
                        :notification="notification"
                        :pagination="pagination"
                        :rows-per-page="pagination.rowsPerPage"
                        :total-items="pagination.meta.totalItems"
                        :lazy-loading="lazyLoading"
                        :no-data-text="noDataText"
                        :header-margin="110"
                        :slim-cells="true"
                        backend-filtering
                        sticky-panel
                        hide-sort
                        hide-pagination
                        @update-pagination="updateAlerts"
                        @search="onSearch" />
                </div>
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import DismissSlideout from '@/components/alerts/DismissSlideout';
import GroupsPickerGlobal from '@/components/globals/GroupsPickerGlobal';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import Loader from '@/components/globals/Loader';
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import { debounce } from 'lodash';
import { SET_ENABLING_ACTIVE } from '@/store/mutation-types';

export default {
    name: 'CommunicationCentral',
    title: 'Alerts',
    components: {
        CustomTable,
        DismissSlideout,
        ActionButton,
        GroupsPickerGlobal,
        StyledInterface,
        StyledCard,
        Loader,
        Icon,
    },
    data() {
        return {
            loading: true,
            loadingAll: false,
            dismissSlideoutOpen: false,
            dismissId: '',
            dismissedCount: 0,
            dismissedSelected: false,
            alerts: [],
            pagination: {
                rowsPerPage: 25,
                page: 1,
                meta: {
                    totalItems: 1,
                    current_page: 1,
                    last_page: 1,
                    to: 1,
                    from: 1,
                }
            },
            alertType: '',
            allSelected: true,
            lazyLoading: true,
            noDataText: '',
            monitors: [],
            counts: [],
            allAlertsCount: 0,
            raw: [],
            search: '',
            searchTypesField: '',
            notification: '',
            fields: [
                {
                    id: 'account',
                    header: 'Account',
                    value: 'dealer',
                    align: 'left',
                    width: '13%',
                    type: 'component',
                    component: () => import('@/components/account-management/AccountLink'),
                    props: {
                        type: 'dealer'
                    }
                },
                {
                    id: 'alert_type',
                    header: 'Alert Type',
                    value: 'dealer',
                    align: 'left',
                    width: '16%',
                    type: 'component',
                    component: () => import('@/components/alerts/AlertType'),
                },
                {
                    id: 'time_ago',
                    header: 'Time Ago',
                    value: 'created_at',
                    align: 'left',
                    width: '10%',
                    type: 'date-from',
                },
                {
                    id: 'issue',
                    header: 'Issue',
                    value: 'message',
                    align: 'left',
                    width: '35%',
                    type: 'component',
                    component: () => import('@/components/alerts/AlertMessage'),
                },
                {
                    id: 'links',
                    header: 'Action(s)',
                    value: 'actions',
                    align: 'left',
                    width: '15%',
                    type: 'component',
                    component: () => import('@/components/alerts/AlertLinks'),
                },
                {
                    id: 'dismiss-info',
                    header: '',
                    value: '',
                    align: 'left',
                    width: '3%',
                    type: 'component',
                    component: () => import('@/components/alerts/AlertDismissInfo'),
                },
                {
                    id: 'dismiss',
                    header: '',
                    value: '',
                    align: 'left',
                    width: '3%',
                    type: 'component',
                    component: () => import('@/components/alerts/AlertDismiss'),
                    callback: (item) => {
                        if (item.dismissed) {
                            this.enableAlert(item.id);
                        } else {
                            this.dismissSlideoutOpen = true;
                            this.dismissId = item.id;
                        }
                    }
                },
                {
                    id: 'check',
                    header: '',
                    value: '',
                    align: 'left',
                    width: '5%',
                    type: 'component',
                    component: () => import('@/components/alerts/AlertCheck'),
                    callback: (item) => {
                        this.recheckAlert(item.dealer_id, item);
                    }
                }
            ]
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
            agenciesLoading: (state) => state.agency.agenciesLoading,
            groupsLoading: (state) => state.agency.groupsLoading,
            selectedGroups: (state) => state.agency.selectedGroups,
        }),
        filteredAlertTypes() {
            const search = this.searchTypesField === null ? '' : this.searchTypesField;
            const filteredTypes = this.monitors.map(monitor => ({
                ...monitor,
                alert_types: {
                    data: monitor.alert_types.data.filter(type => {
                        if (type.display_name.toLowerCase().includes(search.toLowerCase())) {
                            return type;
                        }
                    })
                }
            }));
            return filteredTypes.filter(type => {
                if (type.alert_types.data.length) {return type}
            });
        },
        selectedGroupsIds() {
            return this.selectedGroups.flatMap(group => {
                return group?.id ? group.id : [];
            });
        }
    },
    watch: {
        selectedGroups: {
            handler() {
                this.loadData();
            },
            deep: true
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        if (!this.agenciesLoading) { this.loadData() }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...mapActions([
            'refreshAlert',
        ]),
        selectAlertType(id) {
            this.alertType = id;
            this.allSelected = false;
            this.dismissedSelected = false;
            this.loadWithLazy();
        },
        selectAll() {
            this.alertType = '';
            this.allSelected = true;
            this.dismissedSelected = false;
            this.loadWithLazy();
        },
        selectDismissed() {
            this.alertType = '';
            this.allSelected = false;
            this.dismissedSelected = true;
            this.loadWithLazy();
        },
        loadWithLazy() {
            this.lazyLoading = true;
            if (this.search) {
                this.$refs.alertsTable.clearSearch();
            } else {
                this.loadAlerts();
            }
        },
        async enableAlert(alertId) {
            try {
                this.loading = true;
                this.$store.commit(SET_ENABLING_ACTIVE, true);
                await this.$apiRepository.enableAlert(alertId);
            } catch (error) {
                this.$flash('An error occurred while enabling alert', 'red');
            } finally {
                this.loading = false;
                this.$store.commit(SET_ENABLING_ACTIVE, false);
                this.loadData();
                this.$flash('An alert has been successfully enabled', 'green');
            }
        },
        closeAndLoad() {
            this.dismissSlideoutOpen = false;
            this.loadData();
        },
        async loadData() {
            this.loadingAll = true;
            await this.getCounts();
            await this.getAllAlertsCount();
            await this.getDismissedAlertsCount();
            await this.loadMonitors();
            await this.loadAlerts();
            this.loadingAll = false;
        },
        handleScroll() {
            const typesWrapper = this.$refs.typesWrapper;
            const typesAnchor = this.$refs.typesAnchor;
            const typesAnchorOffset = typesAnchor.getBoundingClientRect().y;
            if (typesAnchorOffset <= 15) {
                typesWrapper.classList.add('sticky');
            } else {
                typesWrapper.classList.remove('sticky');
            }
        },
        onSearch: debounce(async function(search) {
            if (search) {
                this.search = search;
            } else {
                this.search = '';
            }
            await this.loadAlerts();
        }, 500),
        async updateAlerts(pagination, lazyLoading) {
            Object.assign(this.pagination, pagination);
            await this.loadAlerts(lazyLoading);
        },
        async loadAlerts(lazyLoading = false) {
            try {
                this.loading = true;
                if (!lazyLoading) {this.pagination.page = 1}
                const data = {
                    q: this.search,
                    agency_id: this.currentAgency.id,
                    filters: {
                        group_ids: this.selectedGroupsIds,
                        dismissed: this.dismissedSelected ? 'true' : 'false',
                        alert_type_id: this.alertType
                    },
                    disable_fuzziness: true,
                    page: this.pagination.page,
                    page_size: this.pagination.rowsPerPage
                };
                const response = await this.$apiRepository.getAlerts(data);

                if (lazyLoading) {
                    this.alerts = [...this.alerts, ...response.data.data];
                } else {
                    this.alerts = response.data.data;
                }

                this.raw = response.data;

                if (this.alerts.length === 0) {
                    this.lazyLoading = false;
                    if (this.search) {
                        this.noDataText = 'Sorry, but nothing matched your search criteria...';
                    } else {
                        this.noDataText = 'Yay! Your organization has no alerts of this type';
                    }
                }

                const newpagination = { ...this.pagination };
                newpagination.meta.totalItems = this.raw.meta.total;
                newpagination.meta.current_page = this.raw.meta.current_page;
                newpagination.meta.last_page = this.raw.meta.last_page;
                newpagination.meta.to = this.raw.meta.to;
                newpagination.meta.from = this.raw.meta.from;
                this.pagination = newpagination;
            } catch (error) {
                console.log(error);
                this.$flash('An error occurred while loading alerts', 'red');
            } finally {
                this.loading = false;
            }
        },
        async getCounts() {
            try {
                const { data } = await this.$apiRepository.getCounts(this.currentAgency.id, this.selectedGroupsIds);
                this.counts = data.aggregations.alert_type_id;
            } catch (error) {
                this.$flash('An error occurred while loading alert counts', 'red');
            }
        },
        async getAllAlertsCount() {
            try {
                const { data } = await this.$apiRepository.getAllAlertsCount(this.currentAgency.id, this.selectedGroupsIds);
                this.allAlertsCount = data.meta.total;
            } catch (error) {
                this.$flash('An error occurred while loading all alert counts', 'red');
            }
        },
        async getDismissedAlertsCount() {
            try {
                const { data } = await this.$apiRepository.getDismissedAlertsCount(this.currentAgency.id, this.selectedGroupsIds);
                this.dismissedCount = data.meta.total;
            } catch (error) {
                this.$flash('An error occurred while loading dismissed alert counts', 'red');
            }
        },
        async loadMonitors() {
            try {
                const { data } = await this.$apiRepository.getMonitors();
                const monitorsPlusCounts = data.map(monitor => ({
                    ...monitor,
                    alert_types: {
                        data: monitor.alert_types.data.map(type => {
                            const currentCount = this.counts.find(count => count.key === type.id);
                            const countObj = {
                                count: currentCount?.count ?? 0
                            };
                            return Object.assign(type, countObj);
                        })
                    }
                }));
                this.monitors = monitorsPlusCounts;
            } catch (error) {
                this.$flash('An error occurred while loading alert types', 'red');
            }
        },
        async recheckAlert(dealerId, alert) {
            await this.refreshAlert({ dealerId, alertTypeId: alert.alert_type_id });
            this.loadAlerts();
        }
    }
};
</script>

<style lang="scss">
.groups-picker {
    border-bottom: 1px solid $gray-light;
    padding: 15px 47px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    &__holder {
        max-width: 255px;
    }
}
.communication-center {
    .cc {
        display: flex;
        position: relative;
        clip-path: inset(0);
        &__alerts {
            width: 285px;
            position: relative;
        }
        &__table {
            width: calc(100% - 285px);
            overflow: hidden;
            border-left: 1px solid #E0E0E0;
            .custom-table-container {
                padding: 0;
                .custom-table .table-cell {
                    font-size: 12px;
                }
            }
        }
    }
    .filter-row-container .field {
        margin-left: 15px;
    }
    .custom-table-container .custom-table .sticky-wrapper.sticky {
        box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.1);
    }
    .types {
        width: 100%;
        &__title {
            padding: 0 20px;
            height: 45px;
            display: flex;
            align-items: center;
            color: $blue-primary;
            font-size: 16px;
            border-bottom: 1px solid #E0E0E0;
        }
        &__search {
            padding: 15px;
            border-bottom: 1px solid #E0E0E0;
        }
        &.sticky {
            min-height: calc(100vh - 70px);
            .types__content {
                position: fixed;
                height: calc(100vh - 150px);
                overflow: auto;
                width: 285px;
                top: 120px;
                padding-bottom: 60px;
            }
            .types__search {
                position: fixed;
                top: 70px;
                height: 50px;
                padding: 0 0 5px 20px;
                display: flex;
                align-items: center;
                width: 285px;
                box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.1);
                .v-input {
                    transform: scale(0.8) translateX(-30px);
                }
            }
        }
        &__all {
            padding: 5px 10px;
            border-bottom: 1px solid #E0E0E0;
            font-size: 12px;
            font-weight: bold;
        }
        &__single {
            padding: 10px 15px;
            padding-bottom: 30px;
            border-bottom: 1px solid #E0E0E0;
            &:last-of-type {
                margin-bottom: 56px;
            }
            .types__inner {
                padding: 8px 35px 8px 5px;
                width: calc(100% + 5px);
            }
        }
        &__subtitle {
            font-weight: 700;
            font-size: 12px;
            padding: 0 5px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }
        &__item {
            border-bottom: 1px solid #E0E0E0;
            padding: 5px 0;
        }
        &__inner {
            padding: 8px 35px 8px 10px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            font-size: 12px;
            cursor: pointer;
            position: relative;
            &:hover {
                background-color: #D9F1FC;
            }
            &.selected {
                background-color: #D9F1FC;
                pointer-events: none;
            }
        }
        &__count {
            position: absolute;
            right: 10px;
            background: $error-500;
            padding: 0 5px;
            color: white;
            border-radius: 4px;
            font-size: 11px;
            font-weight: 700;
            &.zero {
                background: transparent;
                color: #4E555B;
                font-weight: 600;
            }
        }
        &__text {
            margin-left: 7px;
        }
    }
}
</style>
